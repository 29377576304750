import React from "react";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Form, Modal, Row, Col  } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import ModalForEdit from './modalForEdit';
import ModalForCreate from './modalForCreate';

const Phonebook = () => {
    const [loading, setLoading] = useState(false)
    const [phonebook, setPhonebook] = useState(null);
    const [etablissement, setEtablissement] = useState();
    const [profile, setProfile] = useState();
    const [tabulation, setTabulation] = useState();
    const [totalSize, setTotalSize] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowIconPicker, setModalShowIconPicker] = useState(false);
    const packIconRef = useRef(null);
    const iconNameRef = useRef(null);
    const [iconSelected, setIconSelected] = useState(null);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [modalForEditShow, setModalForEditShow] = useState(false);

    /**
         * Get the size of the profile table when the table is updated
         */
    const getTotalSize = async () => {
        //Get total size
        if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
            setLoading(true)
            let { data, error, count } = await supabase.from('phonebook')
                .select('*', { count: 'exact', head: true })
                .eq('etablissement', etablissement)
                .eq('profile', profile)
                .eq('tabulation', tabulation);
            console.log("Total size = " + count + " - " + data + " - " + error);
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            setTotalSize(count);
            console.log("Total size = " + count);
            setLoading(false);
        } else {
            console.log("Error in getPhonebook - etablissement " + etablissement + " or profile " + profile + " or tabulation " + tabulation + " is undefined");
        }

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const getPhonebook = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getTotalSize();

        setLoading(true)
        console.log("(Phonebook)Get phonebook in db with " + etablissement + "in profile " + profile + " in tabulation " + tabulation);
        if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
            let { data, error } = await supabase
                .from('phonebook')
                .select('*')
                .eq('etablissement', etablissement)
                .eq('profile', profile)
                .eq('tabulation', tabulation)
                .order('ordre', { ascending: true })
                .range(firstIndex, lastIndex)

            if (error) {
                console.warn(error)
            } else if (data) {
                //console.log(data);
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                setPhonebook(data);
            }
        } else {
            console.log("Error in getPhonebook - etablissement " + etablissement + " or profile " + profile + " or tabulation " + tabulation + " is undefined");
        }

        setLoading(false);
    }

    const updateTablePage = () => {
        const currentIndex = (page - 1) * sizePerPage;
        getPhonebook(currentIndex, currentIndex + sizePerPage - 1);
    };

    useEffect(() => {
        updateTablePage();
    }, [etablissement, profile, tabulation]);

    /**
            * Update one value of a row
            * @param {*} itemId Id of the order to update
            * @param {*} dataField Data field to update
            * @param {*} newOrderValue New value of the order
            */
    const updateInPhonebook = async (itemId, dataField, newOrderValue) => {

        setLoading(true)
        console.log("*Update phonebook in db with " + etablissement + "in profile " + profile + " in tabulation " + tabulation);
        console.log("ItemID = " + itemId + "in row = " + dataField + "and value = " + newOrderValue);
        if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
            if(dataField === "numero_long_court"){
                console.log("Update numero_long_court = " + newOrderValue);
            }else {
                let { data, error } = await supabase
                .from('phonebook')
                .update({ [dataField]: newOrderValue })
                .eq('etablissement', etablissement)
                .eq('profile', profile)
                .eq('tabulation', tabulation)
                .eq('id', itemId)
                .select()

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }
            }

            updateTablePage();
        } else {
            console.log("Error in Updating " + dataField + " in Phonebook - etablissement " + etablissement + " or profile " + profile + " or tabulation " + tabulation + " is undefined");
        }
        setLoading(false);
    }

    /**
     * Remove id from the selected row
     * @param {*} id
     * @returns
     * 
     */
    const removeIdFromSelectedRow = (id) => {
        setSelectedRow(row => row.filter(item => item.id !== id));
    }

    /**
            * Delete one row in phonebook
            * @param {*} itemId Id of the order to update
            */
    const deleteItemInPhonebook = async (itemId) => {

        setLoading(true)
        if (selectedRow.length > 0) {
            if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
                console.log("Delete row " + selectedRow[0]);                
                let { data, error } = await supabase
                    .from('phonebook')
                    .delete()
                    .eq('etablissement', etablissement)
                    .eq('profile', profile)
                    .eq('tabulation', tabulation)
                    .eq('id',  `${selectedRow[0]}`);

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log("Deleted datas");
                    console.log(data);
                    //If we delete the selected row, we remove the selected row
                    removeIdFromSelectedRow(selectedRow[0]);
                }
                updateTablePage();
            } else {
                console.log("Error in deleting in Phonebook");
            }
        }
        setLoading(false);
    }

    const orderPhonebook = async () => {
        console.log("Order Phonebook");
        setLoading(true)
        if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
            const { data, error } = await supabase.rpc('reorder_phonebook', { one_etablissement: etablissement, one_profile: profile, one_tabulation: tabulation });
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            updateTablePage();
        } else {
            console.log("Error etablissement or profile or tabulation is undefined");
        }
       
        setLoading(false);
    }

    const handleSetRowUp = () => {
        console.log("Set row up");
        if (selectedRow.length > 0) {
            console.log("Move Up row " + selectedRow[0]);
            console.log(phonebook);
            const currentRow = phonebook.find((element) => element.id === selectedRow[0]);
            console.log(currentRow);
            if (currentRow) {
                //Change supabase ordre. If ordre is an integer wihtout decimal, we remove 1.5 to have a decimal number. If not, we remove
                var previousOrdre = currentRow.ordre;
                var delta = 1.5;
                if (previousOrdre % 1 !== 0) { delta = 1.0; }

                //Change in supabase ordre
                updateInPhonebook(selectedRow[0], "ordre", previousOrdre - delta);
                //Update phonebook to maintain the order
                //orderPhonebook();
            } else { console.log("Current row is undefined"); }
        }
    }

    const handleSetRowDown = () => {
        console.log("Set row down");
        if (selectedRow.length > 0) {
            console.log("Move Down row " + selectedRow[0]);
            console.log(phonebook);
            const currentRow = phonebook.find((element) => element.id === selectedRow[0]);
            console.log(currentRow);
            if (currentRow) {
                var previousOrdre = currentRow.ordre;
                var delta = 1.5;
                var modulo = previousOrdre % 1;
                if (modulo !== 0) { delta = 1.0; }
                //Change in supabase ordre
                updateInPhonebook(selectedRow[0], "ordre", previousOrdre + delta);
                //Update phonebook to maintain the order
                //orderPhonebook();
            } else { console.log("Current row is undefined"); }
        }
    }



    function SelectIconGroup(currentRow) {
        return (
            <Form.Group id="icon_groupe">
            <Form.Label>Pack d'icones</Form.Label>
          <Form.Select aria-label="IconGroupe" ref={packIconRef} defaultValue={currentRow ? currentRow.icontype : null}>
            <option>Choisir un type d'icone</option>
            <option value="feather">Feather</option>
            <option value="material-community">Material Community</option>
            <option value="assets">Assets Icon Pack</option>
            <option value="material">Material</option>
            <option value="awsome5">Awsome 5</option>
            <option value="awsome">Awsome</option>
            <option value="foundation">Foundation</option>
            <option value="fontisto">Fontisto</option>
            <option value="custom-icons">Icon Moon Custom Icons</option>
            <option value="ionicons">Ion Icon Pack</option>
          </Form.Select>
          </Form.Group>

        );
    }


    function MyModalForIconSelection(props) {
        return ( <Modal {...props}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Choisir une icone
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IconPicker />
                    </Modal.Body>
        </Modal>)
    }
    const nt_columns = [{
        name: '#',
        selector: row=>row.num,
        sort: true
    },
    {
        name: 'Section',
        selector: row => row.sectiontitle,
        sort: true
    }, 
    {
        cell: row => <IconField iconname={row.iconname} icontype={row.icontype} initiales={row.initiales} style={{ fill: '#43a047' }} />,
		width: '56px', // custom width for icon button
		style: {
			borderBottom: '1px solid #FFFFFF',
			marginBottom: '-1px',
        },
    },
    {
        name: 'Titre',
        selector: row => row.title,
        sort: true
    },
    {
        name: 'Sous Titre',
        selector: row=>row.subtitle,
        sort: true
    },
    {
        name: 'Numéro',
        selector: row => row.numero_court ? row.numero_court : row.numero,
        sort: true
    },
    {
        name: 'Ordre',
        selector: row => row.ordre,
        sort: true
    }
    ];

    const handlePageChange = page => {
		setLoading(true);

        const currentIndex = (page - 1) * sizePerPage;
        getPhonebook(currentIndex, currentIndex + sizePerPage - 1);

        setPage(page);
		setLoading(false);
	};

    //React data table component
    const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        getPhonebook(currentIndex, currentIndex + newPerPage - 1);

		setSizePerPage(newPerPage);
        setPage(page);
		setLoading(false);
	};

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if(selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0){
            setSelectedRow([selectedRows[0].id]);
            if(selectedRows && selectedRows[0]) setIconSelected({iconName: selectedRows[0].iconname, iconType: selectedRows[0].icontype});
    
        }
    };

    return (
        <>
            <AppNavBar />
            <Container >
                <ModalForCreate
                    selectedRowValues={selectedRow}
                    phonebook={phonebook}
                    show={modalShow}
                    whenfinished={() => { console.log("create finished"); setModalShow(false); }}
                    onHide={() => setModalShow(false)}
                    onExited={() => { updateTablePage() }}
                    etablissement={etablissement}
                    profile={profile}
                    tabulation={tabulation}
                />
                <ModalForEdit
                    selectedRowValues={selectedRow}
                    phonebook={phonebook}
                    show={modalForEditShow}
                    whenfinished={() => { console.log("update finished");  setModalForEditShow(false);}}
                    onHide={() => setModalForEditShow(false)}
                    onExited={()=>{updateTablePage()}}/>

                <MyModalForIconSelection
                    show={modalShowIconPicker}
                    onHide={() => setModalShowIconPicker(false)}
                />
                <div class="d-flex">
                    <div class="col-lg-8">
                        <h1><Icon name="rocket" size={32} /> Annuaires </h1>

                        <p class="lead">La liste des numéros de téléphones</p>
                        <h2>Filtres</h2>
                        {/* <div class='d-flex flex-row mb-3'>
                            <SelectEtablissement onChange={setEtablissement} />
                            <SelectProfile forEtablissement={etablissement} onChange={setProfile} />
                            <SelectTabulation forEtablissement={etablissement} forProfile={profile} onChange={setTabulation} />
                        </div>
 */}
                        <Row>
                            <Col>
                            <SelectEtablissement onChange={setEtablissement} />
                            </Col>
                            <Col>
                            <SelectProfile forEtablissement={etablissement} onChange={setProfile} />
                            </Col>
                            <Col>
                            <SelectTabulation forEtablissement={etablissement} forProfile={profile} onChange={setTabulation} />
                            </Col>
                        </Row>

                        {/* <div class='d-flex flex-row mb-3'>
                            <h4>Etablissement: {etablissement} - Profile: {profile} - Tabulation: {tabulation} </h4>
                        </div> */}



                        <>
                            <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowUp() }}><ArrowUpShort size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowDown() }} ><ArrowDownShort size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { orderPhonebook() }}><ListOl size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { setModalShow(true) }}><FilePlus size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { if(selectedRow.length > 0) {setModalForEditShow(true);} else {alert("Aucune ligne n'est sélectionnée pour modification !")} }}><PencilSquare size={16}/></Button>
                            <Button size="sm" variant="outline-danger" onClick={() => { deleteItemInPhonebook() }}><Trash size={16}/></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { setModalShowIconPicker(true) }}><ArrowUpShort size={16}/></Button>
                            {loading ?
                                <div class="d-flex p-2 flex-column align-items-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div> : null}
                        </>
                        {phonebook ?
                            <DataTable
                            columns={nt_columns}
                            data={phonebook}
                            pagination
                            progressPending={loading}
                            paginationServer
                            paginationTotalRows={totalSize}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            selectableRows
                            selectableRowsSingle
                            onSelectedRowsChange={handleOnSelectRow}
                            selectableRowsHighlight
                            striped
                        /> : <div class="spinner-border text-primary" role="status">
                        <span class="sr-only"></span>
                    </div>}
                  </div>
                </div>
            </Container>

        </>

    );
};

export default Phonebook;