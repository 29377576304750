import React from "react";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Form, Modal, Row, Col, Nav, Tab, Card, Stack } from "react-bootstrap";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import ModalForEdit from './modalForEdit';
import ModalForChoosingTabulation from "./modalForChoosingTabulation";
import ModalForCreateTabulations from "./modalForCreateTabulation";
import ModalForEditingTabulations from "./modalForEditTabulation";
import ModalForAddingTabulations from "./modalForAddingTabulation";
 
import { useLocation } from "react-router-dom";
import PhonebookTab from '../components/PhonebookTab';

function TabulationsList(props) {
    const location = useLocation();
    console.log("Location state :");
    console.log(location);
    let profileid = null;
    let etablissementid = null;
    if(location && location.state !== undefined) {
        profileid = location.state?.profileid;
        etablissementid = location.state?.etablissementid;
    } 
    const [loading, setLoading] = useState(false)
    const [tabulations, setTabulations] = useState(null);
    const [etablissement, setEtablissement] = useState();
    const [totalSize, setTotalSize] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalForEditShow, setModalForEditShow] = useState(false);
    const [modalForChoosingTabulation, setModalForChoosingTabulation] = useState(false);
    const [modalForAddingTabulation, setModalForAddingTabulation] = useState(false);
    const [modalForCreateTabulationShow, setModalForCreatingTabulationShow] = useState(false);
    const [modalForEditingTabulationShow, setModalForEditingTabulationShow] = useState(false);
    const [tabulationSelectedInChoosingTabulation, setTabulationSelectedInChoosingTabulation] = useState(null);

    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);

    /**
         * Get the size of the profile table when the table is updated
         */
    const getTotalSize = async () => {
        //Get total size
        if (etablissementid !== undefined) {
            setLoading(true)
            console.log("(Tabulations) Get total Size of phonebook in db with profile " + profileid + " and etablissement " + etablissementid)
            // let { data, error, count } = await supabase.from('tabulations')
            //     .select('*,phonebook_profiles!inner(*)', { count: 'exact', head: true })
            //     .eq('phonebook_profiles.id', profileid)
            let { data, error, count } = await supabase.from('profilestabulations_multiref')
            .select('*,phonebook_profiles!inner(*),tabulations!inner(*)', { count: 'exact', head: true })
            .eq('phonebook_profiles.id', profileid)

            console.log("Total size = " + count + " - " + data + " - " + error);
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            setTotalSize(count);
            console.log("Total size = " + count);
            setLoading(false);
        } else {
            console.log("Error in gettabulations - profile " + profileid);
        }

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const gettabulations = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getTotalSize();

        setLoading(true)
        console.log("(Tabulations)Get phonebook in db with profile " + profileid);
        if (etablissementid !== undefined) {
            // let { data, error } = await supabase
            //     .from('tabulations')
            //     .select('*,phonebook_profiles!inner(*)')
            //     .eq('phonebook_profiles.id', profileid)
            //     // .order('ordre', { ascending: true })
            //     .range(firstIndex, lastIndex)

            let { data, error } = await supabase.from('profilestabulations_multiref')
            .select('*,phonebook_profiles!inner(*),tabulations!inner(*)')
            .eq('phonebook_profiles.id', profileid)
            .order('ordre', { ascending: true })
            .range(firstIndex, lastIndex)
    

            if (error) {
                console.warn(error)
            } else if (data) {
                //console.log(data);
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                setTabulations(data);
                console.log("Row for tabulations:");
                console.log(data);
            }
        } else {
            console.log("Error in getGlobalPhonebook - etablissement " + etablissementid + " is undefined");
        }

        setLoading(false);
    }

    const updateTablePage = () => {
        const currentIndex = (page - 1) * sizePerPage;
        gettabulations(currentIndex, currentIndex + sizePerPage - 1);
    };

    useEffect(() => {
        updateTablePage();
    }, [etablissementid]);

    /**
            * Update one value of a row
            * @param {*} itemId Id of the order to update
            * @param {*} dataField Data field to update
            * @param {*} newOrderValue New value of the order
            */
    const updateInTabulationsMR = async (itemId, dataField, newOrderValue) => {

         setLoading(true)
        console.log("ItemID = " + itemId + "in row = " + dataField + "and value = " + newOrderValue);
        if (itemId !== undefined || dataField !== undefined || newOrderValue !== undefined) {
                let { data, error } = await supabase
                .from('profilestabulations_multiref')
                .update({ [dataField]: newOrderValue })
                .eq('id', itemId)
                .select()

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }

            updateTablePage();
        } else {
            console.log("Error in Updating " + dataField + " in ProfilesTabulations MultiRef");
        }
        setLoading(false);
    }

    /**
     * Remove id from the selected row
     * @param {*} id
     * @returns
     * 
     */
    const removeIdFromSelectedRow = (id) => {
        setSelectedRow(row => row.filter(item => item.id !== id));
    }

    /**
            * Delete one row in phonebook
            * @param {*} itemId Id of the order to update
            */
    const deleteItemInPhonebook = async (itemId) => {

              setLoading(true)
             if (selectedRow.length > 0) {
                     console.log("Delete row " + selectedRow[0]);                
                     let { data, error } = await supabase
                         .from('profilestabulations_multiref')
                         .delete()
                         .eq('id',  `${selectedRow[0]}`);
     
                     if (error) {
                         console.warn(error)
                     } else if (data) {
                         console.log("Deleted datas");
                         console.log(data);
                         //If we delete the selected row, we remove the selected row
                         removeIdFromSelectedRow(selectedRow[0]);
                     }
                     updateTablePage();
                 
             }
             setLoading(false); 
    }

    const orderPhonebook = async () => {

                 console.log("Order Phonebook");
                setLoading(true)
                if (profileid !== undefined ) {
                    const { data, error } = await supabase.rpc('reorder_profilestabulations_multiref', { one_profileid : profileid });
                    if (error) {
                        console.warn(error)
                    } else if (data) {
                        console.log(data);
                    }
                    updateTablePage();
                } else {
                    console.log("Errorin reordoring profilestabulations_multiref");
                }
               
                setLoading(false); 
                
    }

    const handleSetRowUp = () => {
                console.log("Set row up");
               if (selectedRow.length > 0) {
                   console.log("Move Up row " + selectedRow[0]);
                   console.log(tabulations);
                   const currentRow = tabulations.find((element) => element.id === selectedRow[0]);
                   console.log(currentRow);
                   if (currentRow) {
                       //Change supabase ordre. If ordre is an integer wihtout decimal, we remove 1.5 to have a decimal number. If not, we remove
                       var previousOrdre = currentRow.ordre;
                       var delta = 1.5;
                       if (previousOrdre % 1 !== 0) { delta = 1.0; }
       
                       //Change in supabase ordre
                       updateInTabulationsMR(selectedRow[0], "ordre", previousOrdre - delta);
                       //Update phonebook to maintain the order
                       //orderPhonebook();
                   } else { console.log("Current row is undefined"); }
               } 
    }

    const handleSetRowDown = () => {
             console.log("Set row down");
             if (selectedRow.length > 0) {
                 console.log("Move Down row " + selectedRow[0]);
                 console.log(tabulations);
                 const currentRow = tabulations.find((element) => element.id === selectedRow[0]);
                 console.log(currentRow);
                 if (currentRow) {
                     var previousOrdre = currentRow.ordre;
                     var delta = 1.5;
                     var modulo = previousOrdre % 1;
                     if (modulo !== 0) { delta = 1.0; }
                     //Change in supabase ordre
                     updateInTabulationsMR(selectedRow[0], "ordre", previousOrdre + delta);
                     //Update phonebook to maintain the order
                     //orderPhonebook();
                 } else { console.log("Current row is undefined"); }
             } 
    }

    /*
    function MyModalForIconSelection(props) {
        return (<Modal {...props}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Choisir une icone
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IconPicker />
            </Modal.Body>
        </Modal>)
    }
*/

    const nt_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        name: 'Titre',
        selector: row => row.tabulations.title,
        sort: true
    },
    {
        cell: row => <IconField iconname={row.tabulations.iconname} icontype={row.tabulations.icontype} initiales={row.tabulations.initiales} style={{ fill: '#43a047' }} />,
        width: '56px', // custom width for icon button
        style: {
            borderBottom: '1px solid #FFFFFF',
            marginBottom: '-1px',
        },
    },
    {
        name: 'DbString',
        selector: row => row.tabulations.dbstring,
        sort: true
    },
    {
        name: 'Ordre',
        selector: row => row.ordre,
        sort: true
    },

    ];

    const handlePageChange = page => {
        setLoading(true);

        const currentIndex = (page - 1) * sizePerPage;
        gettabulations(currentIndex, currentIndex + sizePerPage - 1);

        setPage(page);
        setLoading(false);
    };

    //React data table component
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        gettabulations(currentIndex, currentIndex + newPerPage - 1);

        setSizePerPage(newPerPage);
        setPage(page);
        setLoading(false);
    };

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if (selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0) {
            setSelectedRow([selectedRows[0].id]);
            //   if(selectedRows && selectedRows[0]) setIconSelected({iconName: selectedRows[0].iconname, iconType: selectedRows[0].icontype});

        }
    };

    return (
        <>
            <AppNavBar />
            <Container >
                <ModalForAddingTabulations
                   etablissement={etablissementid}
                   profile={profileid}
                   show={modalForAddingTabulation}
                   tabulationsofprofile={tabulations}
                   selectedrowvalues={selectedRow}
                   whenfinished={() => { console.log("create finished"); setModalForAddingTabulation(false); }}
                   onHide={() => setModalForAddingTabulation(false)}
                   onExited={() => { updateTablePage() }}
                   whenCreateTabIsPushed={() => { setModalForAddingTabulation(false); setModalForCreatingTabulationShow(true); }}
                   whenEditingTabIsPressed={(currentTabSelected) => { setTabulationSelectedInChoosingTabulation(currentTabSelected); setModalForAddingTabulation(false); setModalForEditingTabulationShow(true); }}
                />

                <ModalForEdit
                    selectedRowValues={selectedRow}
                    phonebook={tabulations}
                    show={modalForEditShow}
                    whenfinished={() => { console.log("update finished"); setModalForEditShow(false); }}
                    onHide={() => setModalForEditShow(false)}
                    onExited={() => { updateTablePage() }} />


                <ModalForChoosingTabulation 
                    etablissement={etablissementid}
                    profile={profileid}
                    show={modalForChoosingTabulation}
                    tabulationsofprofile={tabulations}
                    selectedrowvalues={selectedRow}
                    whenfinished={() => { console.log("create finished"); setModalForChoosingTabulation(false); }}
                    onHide={() => setModalForChoosingTabulation(false)}
                    onExited={() => { updateTablePage() }}
                    whenCreateTabIsPushed={() => { setModalForChoosingTabulation(false); setModalForCreatingTabulationShow(true); }}
                    whenEditingTabIsPressed={(currentTabSelected) => { setTabulationSelectedInChoosingTabulation(currentTabSelected); setModalForChoosingTabulation(false); setModalForEditingTabulationShow(true); }}
                />

                <ModalForCreateTabulations
                    selectedRowValues={selectedRow}
                    tabulations={tabulations}
                    show={modalForCreateTabulationShow}
                    whenfinished={() => { console.log("create finished"); setModalForCreatingTabulationShow(false); setModalForChoosingTabulation(true); }}
                    onHide={() => {setModalForCreatingTabulationShow(false); setModalForChoosingTabulation(true);}}
                    onExited={() => { updateTablePage() }}
                />

                <ModalForEditingTabulations
                    selectedRowValues={tabulationSelectedInChoosingTabulation}
                    tabulations={tabulations}
                    show={modalForEditingTabulationShow}
                    whenfinished={() => { console.log("create finished"); setModalForEditingTabulationShow(false); setModalForChoosingTabulation(true); }}
                    onHide={() => {setModalForEditingTabulationShow(false); setModalForChoosingTabulation(true);}}
                    onExited={() => { updateTablePage() }}
                />

                <div class="d-flex">
                    <div class="col-lg-8">
                        <h1><Icon name="rocket" size={32} />Liste de réferences sur les tabulations</h1>
                        <h3> pour le profil "{tabulations ? tabulations[0]?.phonebook_profiles?.profile : ""  }" </h3>

                        <p class="lead">Choisir 4 tabulations maximum contenant des annuaires.</p>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                        <>
                            <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowUp() }}><ArrowUpShort size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowDown() }} ><ArrowDownShort size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { orderPhonebook() }}><ListOl size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { setModalForAddingTabulation(true) }}><FilePlus size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { setModalForChoosingTabulation(true) }}><PencilSquare size={16} /></Button>
                            <Button size="sm" variant="outline-danger" onClick={() => { deleteItemInPhonebook() }}><Trash size={16} /></Button>
                            {loading ?
                                <div class="d-flex p-2 flex-column align-items-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div> : null}
                        </>
                        {tabulations ?
                            <DataTable
                                columns={nt_columns}
                                data={tabulations}
                                pagination
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalSize}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={handleOnSelectRow}
                                selectableRowsHighlight
                                striped
                            /> : <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                            </div>}
                    </div>
                </div>
           </Container>

        </>

    );
};

export default TabulationsList;