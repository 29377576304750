import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Stack, Form } from "react-bootstrap";
import { PersonAdd } from 'react-bootstrap-icons';

const AuthorizedUserList = () => {
    const [loading, setLoading] = useState(true)
    const { user, auth } = useAuth();
    const [authorizedUserList, setAuthorizedUserList] = useState(null);
    const [emailForInvite, setEmailForInvite] = useState(null);
    useEffect(() => {
        async function getProfile() {
            setLoading(true)
            let { data, error } = await supabase
                .from('authorizedemails')
                .select('*')

            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
                setAuthorizedUserList(data);
            }

            console.log(user?.app_metadata.claims_admin);
            console.log(user)
            console.log(auth);

            setLoading(false);
        }

        getProfile();
    }, []);

    const handleEmailChange = (event) => {
        setEmailForInvite(event.target.value);
    }

    const sendInvite = async () => {
        console.log("Send invite to " + emailForInvite);
        if(emailForInvite !== null) {
            supabase.functions.invoke('user-invite',  { body: { userEmail: emailForInvite } }).then(({ data }) => {
                console.log(data);
            }
            ).catch((error) => {
                console.log(error);
                alert(error);
            });
        }
    }

    return (
        <>
            <AppNavBar />
            <Container>
            <div class="col-sm-8">
                <div class="jumbotron">
                    <h1>Liste d'utilisateurs autorisés hors domaine</h1>
                    <p class="lead">Le tableau contient la liste des utilisateurs qui peuvent s'autentifier via
                        l'application.</p>
                        <Stack direction="horizontal" gap={3}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address</Form.Label>
                          <Form.Control type="email" className="me-auto" placeholder="name@example.com" value={emailForInvite} onChange={ handleEmailChange}/>
                          </Form.Group>
                            <Button variant="primary" onClick={ () => { sendInvite(); } }><PersonAdd />Inviter un utilisateur</Button>
                        </Stack>


                    <table class="table table-striped">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Email</th>
                                <th scope="col">Etablissement</th>
                                <th scope="col">Profile</th>
                            </tr>
                        </thead>
                        <tbody>
                            {authorizedUserList?.map((item, index) => (
                                <tr key={item.id}>
                                    <th scope="row">
                                        {index + 1}
                                    </th>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.etablissement}
                                    </td>
                                    <td>
                                        {item.profile}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
</Container>
        </>

    );
};

export default AuthorizedUserList;