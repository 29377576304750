import { useState, useEffect, useRef, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import IconMaterial from "react-native-vector-icons/dist/MaterialIcons";
import IconAwsome from "react-native-vector-icons/dist/FontAwesome";
import IconMaterialCommunity from "react-native-vector-icons/dist/MaterialCommunityIcons";
import IconFeather from "react-native-vector-icons/dist/Feather";
import IconAwsome5 from "react-native-vector-icons/dist/FontAwesome5";
import IconFontisto from "react-native-vector-icons/dist/Fontisto";
import IconFoundation from "react-native-vector-icons/dist/Foundation";
import IconIonIcon from "react-native-vector-icons/dist/Ionicons";

import MaterielList from 'react-native-vector-icons/glyphmaps/MaterialIcons.json';
import AwsomeList from 'react-native-vector-icons/glyphmaps/FontAwesome.json';
import MaterialCommunityList from 'react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json';
import FeatherList from 'react-native-vector-icons/glyphmaps/Feather.json';
import Awsome5List from 'react-native-vector-icons/glyphmaps/FontAwesome5Free.json';
import FontistoList from 'react-native-vector-icons/glyphmaps/Fontisto.json';
import FoundationList from 'react-native-vector-icons/glyphmaps/Foundation.json';
import IoniconList from 'react-native-vector-icons/glyphmaps/Ionicons.json';
import IconField from './IconField';
import mystyles from './IconPicker.module.css';

const IconPicker = ({ callback, initialIcon }) => {

  const [iconTypeList, setIconTypeList] = useState(["awsome", "awsome5", "feather", "fontisto", "foundation", "material", "material-community", "ionicons"]);
  const iconList = [AwsomeList, Awsome5List, FeatherList, FontistoList,  FoundationList, MaterielList, MaterialCommunityList, IoniconList];
  const [iconPerLine, setIconPerLine] = useState(5);
  const [iconsPerLine, setIconsPerLine] = useState(5);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [iconListFiltered, setIconListFiltered] = useState([]);
  const [activeIcon, setActiveIcon] = useState(() => {
    if (initialIcon && initialIcon.iconType && initialIcon.iconName) {
      console.log("Initial Icon: " + initialIcon?.iconName + " - " + initialIcon?.iconType);
      let index = iconTypeList.indexOf(initialIcon?.iconType, 0);
      console.log("Index = " + index);
      if(index > 0){
        let iconIndex = Object.keys(iconList[index])?.indexOf(initialIcon?.iconName, 0);
        console.log("Initial Icon: " + initialIcon.iconName + " - " + initialIcon.iconType + " - " + index + " - " + iconIndex)
        return [index, iconIndex];
      } else return [];
    } else {
      return [];
    }
  });

  const [activeTab, setActiveTab] = useState(() => {
    if (initialIcon && initialIcon.iconType) {
      let index = iconTypeList.indexOf(initialIcon.iconType, 0);
      return index;
    } else {
      return 0;
    }
  });

  // const setIconSelected = (icon) => {
  //   if (icon && icon.iconName && icon.iconType) {
  //     console.log("Icon selected = " + icon.iconName + " - " + icon.iconType);
  //     let index = iconTypeList.indexOf(icon.iconType, 0);
  //     iconList[index][icon.iconName] = icon.iconName;
  //   }


  useEffect(() => {
    if(searchInput && searchInput !== "" && searchInput.length >= 3)
      {
        filterIcons(searchInput);
      }
  }, [searchInput]);

  const filterIcons = (aSearch) => {
    var filteredIcons = [];
    for (let i = 0; i < iconList.length; i++) {

      var ids = Object.keys(iconList[i]).reduce((ids, thing) => {
        if (thing.includes(aSearch)) {
          ids.push({ "iconName": thing, "iconType": iconTypeList[i] });
        }
        return ids;
      }, []);
      filteredIcons = filteredIcons.concat(ids);
    }
    setIconListFiltered(filteredIcons);
  }

  useEffect(() => {
    const displayListSearch = () => {
      if(iconListFiltered.length > 0){
        console.log("Found " + iconListFiltered.length + " icons");
        console.log(iconListFiltered);  
      }
    }
    displayListSearch();
  }, [iconListFiltered]);


  return (
    <>
    <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1"><IconField iconname={"search"} icontype={"material"} /></InputGroup.Text>
          <Form.Control
            placeholder="Recherche"
            aria-label="Recherche"
            aria-describedby="basic-addon1"
            value={searchInput}
            onChange={(e) => { setSearchInput(e.target.value); }}
          />
          <InputGroup.Text id="basic-addon2"><Button variant='outline-light' onClick={() => { setSearchInput(""); }}><IconField iconname={"delete"} icontype={"feather"}/></Button></InputGroup.Text>
        </InputGroup>
    <Tab.Container id="list-group-tabs-example" defaultActiveKey={"#link"+activeTab}>
      
        

      {searchInput === "" ?
        <Row >
          <Col sm={4}>
            <div className={mystyles.leftcol}>
              <ListGroup>
                {iconTypeList.map((icon, key) => { return (<ListGroup.Item key={"Tab" + key} action href={"#link" + key} >{icon}</ListGroup.Item>) })}
              </ListGroup>
            </div>
          </Col>
          <Col sm={8}>
            <div className={mystyles.rightcol} >
              <Tab.Content>
                {iconTypeList.map((icon, key) => {
                  let totalRowNumber = Math.ceil(Object.keys(iconList[key]).length / iconPerLine);
                  let totalCellNumber = Object.keys(iconList[key]).length;

                  return (
                    <Tab.Pane eventKey={"#link" + key} key={"Pane" + key}>
                      <div className={mystyles.iconPicker} key={"Div0_" + key}>
                        {Object.keys(iconList[key]).map(
                          (iconName, iconIndex) => (
                            <>
                            { (activeIcon && activeIcon[0] === key && activeIcon[1] === iconIndex ) ? 
                              <Button variant='primary' 
                              onClick={
                                () => { 
                                  console.log("Push " + iconName + " - " + iconTypeList[key]); 
                                  if(callback ) {callback({"iconName" : iconName, "iconType": iconTypeList[key]})};
                                  setActiveIcon([key, iconIndex]); 
                                }}
                                key={"Button_"+iconName+"_"+iconTypeList[key]}>
                              <div className={mystyles.iconCard}>
                                <IconField iconname={iconName} icontype={iconTypeList[key]} key={"Icon_"+iconName+"_"+iconTypeList[key]}/>
                                <>{iconName}</>
                                <div>({iconTypeList[key]})</div>
                              </div>
                            </Button>
                            : <Button variant='outline-light' 
                              onClick={
                                () => { 
                                  console.log("Push " + iconName + " - " + iconTypeList[key]); 
                                  if(callback ) {callback({"iconName" : iconName, "iconType": iconTypeList[key]})};
                                  setActiveIcon([key, iconIndex]); 
                                }}
                                key={"Button_"+iconName+"_"+iconTypeList[key]}>
                              <div className={mystyles.iconCard} key={"Div_" + iconName + "_" + iconTypeList[key]}>
                                <IconField iconname={iconName} icontype={iconTypeList[key]} key={"Icon_"+iconName+"_"+iconTypeList[key]}/>
                                <div>{iconName}</div>
                                <div>({iconTypeList[key]})</div>
                              </div>
                            </Button>
                          }
                            </>

                          )
                        )}
                      </div>
                    </Tab.Pane>)
                })
                }
              </Tab.Content>
            </div>
          </Col>
        </Row>
        :
        <div className={mystyles.iconPicker}>
          {iconListFiltered.map(
            (anIcon, iconIndex) => (
              <Fragment key={"Fragment_" + anIcon.iconName + "_" + anIcon.iconType}>
              { (activeIcon && activeIcon[0] === 0 && activeIcon[1] === iconIndex ) ?
                <Button variant='primary'
                onClick={() => {
                  console.log("Push " + anIcon.iconName + " - " + anIcon.iconType);
                  if(callback ) {callback(anIcon);}
                    setActiveIcon([0, iconIndex]);
                }}
                key={"Button_"+anIcon.iconName+"_"+anIcon.iconType}>
                <div className={mystyles.iconCard}>
                  <IconField iconname={anIcon.iconName} icontype={anIcon.iconType} key={"Icon_"+anIcon.iconName+"_"+anIcon.iconType}/>
                  <div>{anIcon.iconName}</div>
                  <div>({anIcon.iconType})</div>
                </div>
              </Button> :
              <Button variant='outline-light'
                onClick={() => {
                  console.log("Push " + anIcon.iconName + " - " + anIcon.iconType);
                  if(callback ) {callback(anIcon);}
                    setActiveIcon([0, iconIndex]);
                }}
                key={"Button_"+anIcon.iconName+"_"+anIcon.iconType}>
                <div className={mystyles.iconCard}>
                  <IconField iconname={anIcon.iconName} icontype={anIcon.iconType} key={"Icon_"+anIcon.iconName+"_"+anIcon.iconType}/>
                  <div>{anIcon.iconName}</div>
                  <div>({anIcon.iconType})</div>

                </div>
              </Button> }
              </Fragment>
            )
          )}
        </div>
      }

    </Tab.Container>
    </>

  );

};

export default IconPicker;


/*
:
                
              }

              */