import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

function SelectTabulation( props ) {
    const [tabulationList, setTabulationList] = useState([]);
    const { forProfile, forEtablissement, onChange } = props;
    
    // useEffect(() => {
    //     console.log("(SelectTabulation)Etablissement in tabulation" + forEtablissement);
    //     console.log("(SelectTabulation)Profile in tabulation" + forProfile);
    // }, [forEtablissement, forProfile]);

    const handleTabulationChange = (value) => {
        console.log("(SelectTabulation) Call On Change - Tabulation choosen by select: " + tabulationList[value]);
        onChange(tabulationList[value]);
    }

    useEffect(() => {
        onChange(tabulationList[0]);
    }, [tabulationList, onChange]);

    useEffect(() => {
        async function getTabulation() {
            console.log("(SelectTabulation) Etablissement in tabulation " + forEtablissement + " profile " + forProfile);
            if( forEtablissement !== undefined || forProfile !== undefined) {
           supabase.from('distinct_tabulation').select().eq('etablissement', forEtablissement).eq('profile', forProfile).then(({data, error}) => {
               if(data) {
                console.log("(SelectTabulation) Distinct tabulation (" + data.length + ") - Etablissement " + forEtablissement + " profile " + forProfile);
                console.log(data);
                var aReceivedTabulationList = [];
                data.forEach((item, index) => {
                    aReceivedTabulationList.push(item.tabulation);
                });
                setTabulationList(aReceivedTabulationList);
               

            } else if(error) {
                console.log("(SelectTabulation)Error distinct tabulation");
                console.log(error);
            };
           }).catch((error) => {
               console.log("(SelectTabulation)Error distinct tabulation");
               console.log(error);
           });
        }
       };
       getTabulation();
   }, [forProfile]);

    // useEffect(() => {
    //     console.log(tabulationChoosen);
    // }, [tabulationChoosen]);

    return (
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Tabulation</Form.Label>
        <Form.Select aria-label="Sélectionner le tabulation" onChange={(event) => {handleTabulationChange(event.target.value)}} id="tabulation">
            {tabulationList.map((address, key) => <option key={key} value={key}>{address} </option>)}
        </Form.Select>
        </Form.Group>
  );
}

export default SelectTabulation;