import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

function SelectProfile( props ) {
    const [profileList, setProfileList] = useState([]);
    const { forEtablissement, onChange } = props;
    

    // useEffect(() => {
    //     console.log("(SelectProfile) Etablissement in profile" + forEtablissement);
    // }, [forEtablissement]);

    const handleprofileChange = (value) => {
        console.log("(SelectProfile) Profile choosen by select: " + profileList[value]);
        onChange(profileList[value]);
    }

    useEffect(() => {
        onChange(profileList[0]);
    }, [profileList, onChange]);

    useEffect(() => {
        async function getProfile() {
            //console.log("(SelectProfile) Etablissement in profile" + forEtablissement);
            if( forEtablissement !== undefined) {
            supabase.from('distinct_profile').select().eq('etablissement', forEtablissement).then(({data, error}) => {
                if(data) {
                    console.log("(SelectProfile) Distinct profile (" + data.length + ") - Etablissement " + forEtablissement);
                    console.log(data);
                    var aReceivedprofileList = [];
                    data.forEach((item, index) => {
                        aReceivedprofileList.push(item.profile);
                    });
                    setProfileList(aReceivedprofileList);

                } else if(error) {
                    console.log("(SelectProfile) Error distinct profile");
                    console.log(error);
                };
            }).catch((error) => {
                console.log("(SelectProfile) Error distinct profile");
                console.log(error);
            });
        }
       };
       getProfile();
   }, [forEtablissement]);

    // useEffect(() => {
    //     console.log(profileChoosen);
    // }, [profileChoosen]);

    return (
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Profile</Form.Label>
        <Form.Select aria-label="Sélectionner le profile" onChange={(event) => {handleprofileChange(event.target.value)}} id="profile">
            {profileList.map((address, key) => <option key={key} value={key}>{address} </option>)}
        </Form.Select>
        </Form.Group>
    

  );
}

export default SelectProfile;