import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Card, Form, Modal, Row, Alert, ButtonGroup } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare, BorderLeft, Pencil } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import InputGroup from 'react-bootstrap/InputGroup';


const ModalForEdit = (props) => {
    const { selectedRowValues, phonebook, whenfinished} = props;
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const shortTelRef = useRef(null);
    const longTelRef = useRef(null);
   // const initialesRef = useRef(null);
    const sectionRef = useRef(null);
   // const packIconRef = useRef(null);
   // const iconNameRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [iconSelected, setIconSelected] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        const fixCurrentRow = () => {
            let currentRow = null;
            if( selectedRowValues && selectedRowValues.length > 0 && phonebook){
                currentRow = phonebook.find((element) => element.id === selectedRowValues[0]);
                if(currentRow){
                    //console.log("(modalForEdit)Current Row = " + currentRow.id + " - " + currentRow.title + " - " + currentRow.subtitle + " - " + currentRow.numero_court + " - " + currentRow.numero + " - " + currentRow.iconname + " - " + currentRow.icontype);
                    console.log(currentRow);
                } else {
                    //Set to null instead of undefined
                    currentRow = null;
                }
            };
            setCurrentRow(currentRow);
        }
        fixCurrentRow();
    }, [phonebook, selectedRowValues]);


    const getIconForm = () => {
        let anIcon = {};
            if(iconSelected){
                anIcon = iconSelected;
            } else if (currentRow?.iconname && currentRow?.icontype){
                anIcon = {iconName: currentRow?.iconname, iconType: currentRow?.icontype};
            } else {
                anIcon = {iconName: 'user-md', iconType: 'awsome'};
            }
            //console.log("(modalForEdit)Icon selected = " + anIcon.iconName + " - " + anIcon.iconType);
            return anIcon;
        }

    const handleSubmitEditTel = async (e) => {
        e.preventDefault();
        updateInPhonebook();
        if(whenfinished) whenfinished();

    }
            
 /**
* Update one value of a row
*/
 const updateInPhonebook = async () => {

    setLoading(true);
    let iconToUpdate= getIconForm();
    console.log("Update ItemID = " + selectedRowValues[0]);
    try {
            let { data, error } = await supabase
            .from('phonebook')
            .update({
                'sectiontitle': sectionRef.current?.value, 
                'title': titleRef.current?.value, 
                'subtitle': subtitleRef.current?.value, 
                'numero_court': shortTelRef.current?.value, 
                'numero': longTelRef.current?.value,
                'iconname': iconToUpdate.iconName,
                'icontype': iconToUpdate.iconType,
             })
            .eq('id', selectedRowValues[0])
            .select()

            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
    } catch (error) {
        console.log("Error in Updating Phonebook" + error);
    }
    setLoading(false);
}


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modifier un numéro de téléphone
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                                <div class="d-flex p-2 flex-column align-items-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div> : null}
                <Form onSubmit={handleSubmitEditTel}>
                    <Row className="mb-3">
                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Section</InputGroup.Text>
                        <Form.Control type="text" ref={sectionRef} defaultValue={currentRow ? currentRow.sectiontitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon2">Titre</InputGroup.Text>
                        <Form.Control type="text" ref={titleRef} defaultValue={currentRow ? currentRow.title : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon3">Sous titre</InputGroup.Text>
                        <Form.Control type="text" ref={subtitleRef} defaultValue={currentRow ? currentRow.subtitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon4">Téléphone Court</InputGroup.Text>
                        <Form.Control type="text" ref={shortTelRef} defaultValue={currentRow ? currentRow.numero_court : null} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon5">Téléphone Long</InputGroup.Text>
                        <Form.Control type="text" ref={longTelRef} defaultValue={currentRow ? currentRow.numero : null}/>
                        </InputGroup>

                        <IconPicker callback={ 
                            (icon) => {
                                console.log("Choosed " + icon.iconName + " - " +icon.iconType);
                                setIconSelected(icon);
                            } 
                            } initialIcon={getIconForm()}/>
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><Pencil size={32}/>Modifier</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalForEdit;