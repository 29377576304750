import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown} from 'react-bootstrap';
import appicon from '../Icon150x150.png';
import { useAuth } from "../context/AuthProvider";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { PersonCircle } from 'react-bootstrap-icons';

function AppNavBar() {

  const { user, auth, signOut } = useAuth();
  
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const { error } = await signOut();
      console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  const titleNavBar = ( <><PersonCircle size={32}/> {user?.user_metadata?.first_name} {user?.user_metadata?.last_name}</>);

  return (
    <>
<Navbar>
      <Container>
        <Navbar.Brand href="#home">
        <img
              alt=""
              src={appicon}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />Hopfone</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
           <Nav className="me-auto">
            {!auth && (
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            )}
            {auth && (
              <Nav.Link as={Link} to="/phonebook">
                Annuaire
              </Nav.Link>
            )}
            {auth && (
              <Nav.Link as={Link} to="/phonebook_profiles">
                Profiles
              </Nav.Link>
            )}
            {/* {auth && (
              <Nav.Link as={Link} to="/home">
                Home
              </Nav.Link>
            )} */}
            

          </Nav>
          <Nav>
            {auth && (
              <>
              <NavDropdown title={titleNavBar} id="navbarScrollingDropdown">
              {user?.app_metadata.claims_admin ? (
              <>
              <NavDropdown.Item as={Link} to="/userlist">Liste Utilisateurs</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/authorizeduserlist">Utilisateurs autorisés</NavDropdown.Item>
              </>
            ) : null}
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/account">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>Déconnexion</NavDropdown.Item>
              </NavDropdown>
                </>
              )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default AppNavBar;