import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Card, Form, Modal, Row, Alert, ButtonGroup } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare, BorderLeft, Pencil } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import InputGroup from 'react-bootstrap/InputGroup';


const MyModalForCreatingPhoneItem = (props) => {
    const { selectedRowValues, phonebook, whenfinished, etablissement, profile, tabulation} = props;
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const shortTelRef = useRef(null);
    const longTelRef = useRef(null);
    const sectionRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [iconSelected, setIconSelected] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        const fixCurrentRow = () => {
            let currentRow = null;
            if( selectedRowValues && selectedRowValues.length > 0 && phonebook){
                //console.log("Selected Row Value = " + selectedRowValues[0])
                currentRow = phonebook.find((element) => element.id === selectedRowValues[0]);
                console.log(currentRow);
                if(currentRow){
                    //console.log("(modalForCreate)Current Row = " + currentRow.id + " - " + currentRow.title + " - " + currentRow.subtitle + " - " + currentRow.numero_court + " - " + currentRow.numero + " - " + currentRow.iconname + " - " + currentRow.icontype + " - " + currentRow.ordre);
                    console.log(currentRow);    
                } else {
                    currentRow = null;
                }
            };
            setCurrentRow(currentRow);
        }
        fixCurrentRow();
    }, [phonebook, selectedRowValues]);

    const getIconForm = () => {
        let anIcon = {};
            if(iconSelected){
                anIcon = iconSelected;
            } else if (currentRow?.iconname && currentRow?.icontype){
                anIcon = {iconName: currentRow?.iconname, iconType: currentRow?.icontype};
            } else {
                anIcon = {iconName: 'user-md', iconType: 'awsome'};
            }
            //console.log("((modalForCreatePB))Icon selected = " + anIcon.iconName + " - " + anIcon.iconType);
            return anIcon;
    }

    const handleSubmitCreateTel = async (e) => {
        e.preventDefault();
        createInPhonebook();
        if(whenfinished) whenfinished();
    }

    const createInPhonebook = async () => {
        try {
            setErrorMsg("");
            setLoading(true);

            if (!titleRef.current?.value || !subtitleRef.current?.value || !(shortTelRef.current?.value || longTelRef.current?.value) || !sectionRef.current?.value ) {
                setErrorMsg("Informations manquantes !");
                setLoading(false);
                return;
            }

            //Where to put this new item - First check if there is a selected row
            var ordreToInsert = 0;
            if( currentRow ){
                ordreToInsert = currentRow.ordre + 0.5;
            } else 
            {
                setErrorMsg("Pas de ligne sélectionnée pour ajouter un numéro de téléphone - Ajouté en première position par défaut");
                setLoading(false);
                ordreToInsert = 0.5;
            };

            //Check Icon:
            let anIcon = getIconForm();

            console.log("*Update phonebook in db with " + etablissement + "in profile " + profile + " in tabulation " + tabulation);
            if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
                let { data, error } = await supabase
                    .from('phonebook')
                    .insert({ 
                            'etablissement': etablissement, 
                            'profile': profile, 
                            'tabulation': tabulation, 
                            'sectiontitle': sectionRef.current?.value, 
                            'title': titleRef.current?.value, 
                            'subtitle': subtitleRef.current?.value, 
                            'numero_court': shortTelRef.current?.value, 
                            'numero': longTelRef.current?.value,
                            'currentversion': 1,
                            'iconname': anIcon.iconName,
                            'icontype': anIcon.iconType,
                            'ordre': ordreToInsert
                         })
                    .select()
    
                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }
            } else {
                console.log("Error in adding phone number in Phonebook - etablissement " + etablissement + " or profile " + profile + " or tabulation " + tabulation + " is undefined");
            }
        } catch (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ajouter un numéro de téléphone (ModalForCreate)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitCreateTel}>
                    <Row className="mb-3">
                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Section</InputGroup.Text>
                        <Form.Control type="text" ref={sectionRef} defaultValue={currentRow ? currentRow.sectiontitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon2">Titre</InputGroup.Text>
                        <Form.Control type="text" ref={titleRef} defaultValue={currentRow ? currentRow.title : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon3">Sous titre</InputGroup.Text>
                        <Form.Control type="text" ref={subtitleRef} defaultValue={currentRow ? currentRow.subtitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon4">Téléphone Court</InputGroup.Text>
                        <Form.Control type="text" ref={shortTelRef} defaultValue={currentRow ? currentRow.numero_court : null} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon5">Téléphone Long</InputGroup.Text>
                        <Form.Control type="text" ref={longTelRef} defaultValue={currentRow ? currentRow.numero : null}/>
                        </InputGroup>

                        <IconPicker callback={ 
                            (icon) => {
                                console.log("Choosed " + icon.iconName + " - " +icon.iconType);
                                setIconSelected(icon);
                            } 
                            } initialIcon={getIconForm()}/>
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><FilePlus size={32}/>Ajouter</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default MyModalForCreatingPhoneItem;