// in src/IconField.tsx
import IconMaterial from "react-native-vector-icons/dist/MaterialIcons";
import IconAwsome from "react-native-vector-icons/dist/FontAwesome";
import IconMaterialCommunity from "react-native-vector-icons/dist/MaterialCommunityIcons";
import IconFeather from "react-native-vector-icons/dist/Feather";
import IconAwsome5 from "react-native-vector-icons/dist/FontAwesome5";
import IconFontisto from "react-native-vector-icons/dist/Fontisto";
import IconFoundation from "react-native-vector-icons/dist/Foundation";
import IconIonIcon from "react-native-vector-icons/dist/Ionicons";



const IconField = ({ iconname, icontype, initiales, size, color }) => {
    switch (icontype) {
        case 'material':
            return <IconMaterial name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'awsome':
            return <IconAwsome name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'material-community':
            return <IconMaterialCommunity name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'feather':
            return <IconFeather name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'awsome5':
            return <IconAwsome5 name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'fontisto':
            return <IconFontisto name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'foundation':
            return <IconFoundation name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'ionicon':
            return <IconIonIcon name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        case 'ionicons':
            return <IconIonIcon name={iconname} size={size ? size : 24} color={color ? color : "#000000"}/>;
        default:
            if(initiales && initiales !== '' && initiales !== "NULL") {
                return <>{initiales}</>
            } else {
                return <h5>NF</h5>;
            }
    }
};

export default IconField;