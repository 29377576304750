import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

function SelectEtablissement( {onChange, onChangeId} ) {
    const [etablissementList, setEtablissementList] = useState([]);
    const [etablissementChoosen, setEtablissementChoosen] = useState(0);

    const handleEtablissementChange = (value) => {
        console.log("(SelectEtablissement) Etablissement choosen by select: " + etablissementList[value]);
        setEtablissementChoosen(value);
        onChange(etablissementList[value].titre);
        if(onChangeId)onChangeId(etablissementList[value]?.id);
    }

    useEffect(() => {
        console.log("(SelectEtablissement) On change 0 :" +etablissementList[0]);
        onChange(etablissementList[0]?.titre);
        if(onChangeId)onChangeId(etablissementList[0]?.id);
        setEtablissementChoosen(0);
    }, [etablissementList, onChange, onChangeId]);

    useEffect(() => {
         async function getEtablissement() {
            supabase.from('etablissement').select().then(({data}) => {
                console.log("(SelectEtablissement) Distinct etablissement (" + data.length + ")");
                console.log(data);
                var etablissementList = [];
                data.forEach((item, index) => {
                    etablissementList.push({"titre": item.titre, "id": item.id, "email": item.email});
                });
                setEtablissementList(etablissementList);
            }).catch((error) => {
                console.log("Error etablissement ID - use distinct_etablissement instead");
                console.log(error);

                supabase.from('distinct_etablissement').select().then(({data}) => {
                    console.log("(SelectEtablissement) Use Distinct etablissement (" + data.length + ")");
                    console.log(data);
                    var etablissementList = [];
                    data.forEach((item, index) => {
                        etablissementList.push({"titre": item.etablissement});
                    });
                    setEtablissementList(etablissementList);
                }).catch((error) => {
                    console.log("Error distinct etablissement");
                    console.log(error);
                });
            });         
        };
        getEtablissement();
    }, []);

    return (
        <>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Etablissement</Form.Label>
    <Form.Select aria-label="Sélectionner l'établissement" onChange={(event) => {handleEtablissementChange(event.target.value)}} >
        {etablissementList.map((address, key) => <option key={key} value={key}>{address.titre} ({address?.id} / {address?.email}) </option>)}
    </Form.Select>
    </Form.Group>
        </>
  );
}

export default SelectEtablissement;