import { useRef, useState } from "react";
import { Alert, Button, Card, Form, Row, Col, Modal, Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { Container } from "react-bootstrap";
import AppNavBar from './AppNavBar'

const SignUp = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const termsAcceptedRef = useRef(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signUp } = useAuth();

  const [isModalForPwdShown, setIsModalForPwdShown] = useState(false);

  const handleClose = () => setIsModalForPwdShown(false);
  const handleShow = () => {
    setIsModalForPwdShown(true);
  }

  //const [passwordVisible, setPasswordVisible] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrorMsg("");
      setLoading(true);
      if (!passwordRef.current?.value || !emailRef.current?.value || !firstNameRef.current?.value || !lastNameRef.current?.value) {
        setErrorMsg("Informations manquantes !" );
        setLoading(false);
        return;
      }
      if ( !termsAccepted ) {
        setErrorMsg("Veuillez accepter les conditions d'utilisation");
        setLoading(false);
        return;
      }

      const {
        data: { user, session },
        error
      } = await signUp(emailRef.current.value, passwordRef.current.value, firstNameRef.current.value, lastNameRef.current.value );
      console.log(user);
      console.log(session);
      console.log(error);
      if( user && !session ){
        handleShow();
      }
      if (error) setErrorMsg(error.message);
      if (user && session) navigate("/");
    } catch (error) {
      setErrorMsg(error);
    }
    setLoading(false);
  };

  const handleChangeTerms = () => {
    setTermsAccepted(!termsAccepted);
  }

  return (
    <div className="bgimage">
    <AppNavBar />
    <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "90vh"}}>
    <div className="w-100" style={{ maxWidth: "400px"  }}>
        {loading ? <div class="d-flex p-2 flex-column align-items-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only"></span>
                    </div>
                    </div> : null }
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">S'inscire</h2>
          <Form onSubmit={handleSubmit}>
            
          <Row className="mb-3">
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="firstName">
              <Form.Label>Prénom</Form.Label>
              <Form.Control type="text" ref={firstNameRef} required />
            </Form.Group>
            <Form.Group id="lastName">
              <Form.Label>Nom</Form.Label>
              <Form.Control type="text" ref={lastNameRef} required />
            </Form.Group>
            </Row>
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    inline 
                    label="J'ai lu et j'accèpte les conditions générales d'utilisation." 
                    type="checkbox" 
                    id={"accepteTemsId"} 
                    checked={termsAccepted}
                    onChange={handleChangeTerms}
                    ref={termsAcceptedRef}/>
                    </Form.Group>
            {errorMsg && (
              <Alert
                variant="danger"
                onClose={() => setErrorMsg("")}
                dismissible>
                {errorMsg}
              </Alert>
            )}
            <div className="text-center mt-2">
              <Button disabled={loading} type="submit" className="w-50">
                S'inscire
              </Button>
            </div>
          </Form>
        </Card.Body>
       {/*  <div className="w-100 text-center mt-2">
          New User? <Link to={"/register"}>Register</Link>
        </div> */}
      </Card>
    </div>
    <Modal show={isModalForPwdShown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Compte utilisateur créé</Modal.Title>
        </Modal.Header>
        <Modal.Body>Si vous ne l'avez pas encore fait, vous devez valider votre compte en cliquant sur le lien envoyé dans votre boite mail.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {handleClose(); navigate("/"); }}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </div>

  );
};

export default SignUp;