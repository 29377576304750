import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Col, Row, Stack, CloseButton, Form, Card } from "react-bootstrap";
//import { BsTrash } from 'react-icons/bs';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { PersonAdd } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Image from 'react-bootstrap/Image';


const UserList = () => {
    const [loading, setLoading] = useState(true)
    const { user, auth } = useAuth();
    const [userList, setUserList] = useState(null);
    //const [authUserList, setAuthUserList] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserAuth, setSelectedUserAuth] = useState(null);
    const [selectedRow, setSelectedRow] = useState([]);
    const [shoudUpdateProfile, setShouldUpdateProfile] = useState(false);
    const [sSizePerPage, setSSizePerPage] = useState(10);
    const [sPage, setSPage] = useState(1);
    const [sTotalSize, setSTotalSize] = useState(0);
    const [expandedUser, setExpandedUser] = useState(null);
    const [expandedUserAuth, setExpandedUserAuth] = useState(null);
    const passwordRef = useRef(null);
  
    const [aNewSearch, setANewSearch] = useState("")



    /**
     * Get the size of the profile table when the table is updated
     */
    const getTotalSize = async () => {
        //Get total size
        let { data, error, count } = await supabase
            .from('profiles')
            .select('*', { count: 'exact', head: true });
        //console.log("Total size = " + count + " - " + data + " - " + error);
        if (error) {
            console.warn(error)
        } else if (data) {
            console.log(data);
        }
        setSTotalSize(count);
    }

    /**
     * Get the size of the profile table when the table is updated considering the search
     */
    const getTotalSizeSearch = async (search) => {
        //Get total size
        let { data, error, count } = await supabase
            .from('profiles')
            .select('*', { count: 'exact', head: true })
            .or(`first_name.ilike.${search},last_name.ilike.${search},etablissement.ilike.${search},profile.ilike.${search}`);
        //console.log("Total size = " + count + " - " + data + " - " + error);
        if (error) {
            console.warn(error)
        } else if (data) {
            console.log(data);
        }
        setSTotalSize(count);
    }

    /**
     * Get the profile table from the database with pagination
     * @param {*} firstIndex 
     * @param {*} lastIndex 
     */
    const getProfile = async (firstIndex, lastIndex) => {
        setLoading(true)

        //Get the total size of the profile table for pagination
        getTotalSize();

        //Get profiles
        let { data, error } = await supabase
            .from('profiles')
            .select('*')
            .range(firstIndex, lastIndex)

        if (error) {
            console.warn(error)
        } else if (data) {
            //console.log(data);
            data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
            setUserList(data);
        }

        //console.log(user?.app_metadata.claims_admin);
        //console.log(user)
        //console.log(auth);

        setLoading(false);
    };

     /**
     * Get the profile table from the database with pagination
     * @param {*} firstIndex 
     * @param {*} lastIndex
     * @param {*} search  
     */
     const getProfileSearch = async (firstIndex, lastIndex, search) => {
        console.log("Search = " + search + firstIndex + " - " + lastIndex);
        setLoading(true)

        //Get the total size of the profile table for pagination
        getTotalSizeSearch(search);

        //Get profiles
        let { data, error } = await supabase
            .from('profiles')
            .select('*')
            .range(firstIndex, lastIndex)
            .or(`first_name.ilike.${search},last_name.ilike.${search},etablissement.ilike.${search},profile.ilike.${search}`);


        if (error) {
            console.warn(error)
        } else if (data) {
            //console.log(data);
            data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
            setUserList(data);
        }

        //console.log(user?.app_metadata.claims_admin);
        //console.log(user)
        //console.log(auth);

        setLoading(false);
    };

     /**
     * Get the profile table from the database with pagination
     * @param {*} firstIndex 
     * @param {*} lastIndex
     * @param {*} search  
     */
     const getProfileSearchNoPagination = async ( search) => {
        console.log("Search = " + search );
        setLoading(true)

        //Get the total size of the profile table for pagination
        getTotalSizeSearch(search);

        //Get profiles
        let { data, error } = await supabase
            .from('profiles')
            .select('*')
            .or(`first_name.ilike.${search},last_name.ilike.${search},etablissement.ilike.${search},profile.ilike.${search}`);


        if (error) {
            console.warn(error)
        } else if (data) {
            console.log(data);
            data.map((item, index) => { item["num"] =  index + 1; return item; });
            setUserList(data);
        }

        setLoading(false);
    };

    // For init, get the profile list
    useEffect(() => {
        const currentIndex = (sPage - 1) * sSizePerPage;
        getProfile(currentIndex, currentIndex + sSizePerPage - 1);
    }, []);

    useEffect(() => {
        //console.log("Slected row = " + selectedRow);
    }, [selectedRow]);

    /**
     * Get the user data in the authentification table
     * @param {*} id The user id in the authentification table 
     */
    const getUserData = async ({ user, onResult }) => {
        setLoading(true);
        if (user != null) {
            //console.log("Get user details : " + user.id + " - " + user?.first_name + " " + user?.last_name);
            let { data, error } = await supabase.functions.invoke('user-get-one', { body: { userId: user.id } });
            if (error) {
                console.log(error);
                alert(error);
            }
            else if (data?.user) {
                //console.log(data.user);
                onResult(data.user);
                //console.log(data.user.email)
            };
        }
        setLoading(false);
    }

    /**
     * If the selected user is changed, get the user data in the authentification table
     */
    useEffect(() => {
        if(selectedUser) getUserData({ user: selectedUser, onResult: setSelectedUserAuth });
        else {
            setSelectedUserAuth(null);
        }
    }, [selectedUser]);

    /**
     * If the expanded user is changed, get the user data in the authentification table
     */
    useEffect(() => {
        if(expandedUser) getUserData({ user: expandedUser, onResult: setExpandedUserAuth });
        else {
            setExpandedUserAuth(null);
        }
    }, [expandedUser]);
    
    /**
     * Delete the user on the server using an edge function.
     * @param {*} id The Id in the database of the user to delete
     */
    const deleteUser = async (id) => {
        setLoading(true);
        let { data, error } = await supabase.functions.invoke('user-deletion', { body: { userId: id } });
        if (error) {
            console.log(error);
            alert(error);
        }
        else if (data) {
            //console.log(data);
        };
        const currentIndex = (sPage - 1) * sSizePerPage;
        getProfile(currentIndex, currentIndex + sSizePerPage - 1);
        setLoading(false);
    };

    /**
     * Delete the user on the server using an edge function.
     * @param {*} id The Id in the database of the user to delete
     * @param {*} password The new password
     */
    const changePassword = async ({ id, aPassword }) => {
        //console.log("Change password - " + id + " - " + aPassword);
        setLoading(true);
        let { data, error } = await supabase.functions.invoke('user-change-password', { body: { userId: id, newPassword: aPassword } });
        if (error) {
            console.log(error);
            alert(error);
        }
        else if (data) {
            //console.log(data);
        };
        const currentIndex = (sPage - 1) * sSizePerPage;
        getProfile(currentIndex, currentIndex + sSizePerPage - 1);
        setLoading(false);
    };

    const nt_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        avatar: 'Avatar',
        cell: row => (<><Image src={row.avatar_url}></Image></>),
        sort: false,
    },
    {
        name: 'Prénom',
        selector: row => row.first_name,
        sort: true
    }, {
        name: 'Nom',
        selector: row => row.last_name,
        sort: true
    }];

    const handlePasswordChange = (event) => {
        //        console.log("Handle password change : Id = " + expandedUser?.id + " - Password: " + aNewPassword);
        event.preventDefault();
        if (!passwordRef.current?.value ) {
            alert("Oups, il faut définir un mot de passe");
            return;
          }

        if (expandedUser?.id != null) {
            changePassword({ id: expandedUser?.id, aPassword: passwordRef.current.value });
            event.preventDefault();
        } else {
            console.log("Erreur: pas de password defini");
            alert("Oups, il faut définir un mot de passe");
        }
    }

// This is my custom search component
const MySearch = (props) => {
    let input;
    const handleClick = () => {
        getProfileSearchNoPagination(input.value);
//      props.onSearch(input.value);
    };
    const handleClear = () => {
        const currentIndex = (sPage - 1) * sSizePerPage;
        getProfile(currentIndex, currentIndex + sSizePerPage - 1);
    }
    return (
      <div>
        <input
          className="form-control"
          ref={ n => input = n }
          type="text"
        />
        <Button onClick={ handleClick }>Search</Button>
        <Button onClick={ handleClear }>Clear</Button>
      </div>
    );
  };

    /** Display a table with search */
/*     const RemoteFilter = props => (
        <div>
          <ToolkitProvider
            keyField="id"
            data={ props.data }
            columns={ columns }
            search
          >
            {
              toolkitprops => [
                 <MySearch { ...props.searchProps } />,
                <BootstrapTable
                  { ...toolkitprops.baseProps }
                  remote ={ props.remote}
                  onTableChange={ props.onTableChange }
                  striped = { props.striped }
                  expandRow = { props.expandRow }
                  bordered = { props.bordered }
                  pagination = { props.pagination }
                />
              ]
            }
          </ToolkitProvider>
        </div>
      ); */

      //React Data Table Component
      //React data table component
      const handlePageChange = page => {
		setLoading(true);

        const currentIndex = (page - 1) * sSizePerPage;
        getProfile(currentIndex, currentIndex + sSizePerPage - 1);

        setSPage(page);
		setLoading(false);
	};

    const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        getProfile(currentIndex, currentIndex + newPerPage - 1);

		setSSizePerPage(newPerPage);
        setSPage(page);
		setLoading(false);
	};

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        setSelectedRow(selectedRows.map(r => r.id));
    };

    const isRowExpanded = (row) => {
        console.log("Is Row Expanded ? " + row.id);
        if( expandedUser === null ) {
            return false;
        }

        if( expandedUser?.id === row.id ) {
            return true;
        }
        return false;
    }

    const expandableComponent = ({ data }) => {

        return(
                <div>
                        <Container>
                            <Row>
                                <Col xs={10}>
                                    <div class="card-text">Email: <a href={"mailto:" + expandedUserAuth?.email}>{expandedUserAuth?.email}</a></div>
                                </Col>
                                <Col>
                                    <CloseButton onClick={() => { setExpandedUser(null); }} />
                                </Col>
                            </Row>
                            <div class="card-text">Etablissement: {data?.etablissement}</div>
                            <div class="card-text">Profile: {data?.profile}</div>
                            <div class="card-text">Id: {data?.id}</div>
                            <Card style={{ width: '36rem' }}>
                                <Card.Body>
                                    <Form onSubmit={handlePasswordChange}>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <Form.Control type="password" className="me-auto" ref={passwordRef} placeholder="mot de passe" />
                                                </Col>
                                                <Col>
                                                    <Button variant="primary" type="submit">
                                                        Modifier le mot de passe
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Button variant="danger" onClick={() => { deleteUser(data?.id); }}> <BiTrash /> Détruire ce compte</Button>
                                </Card.Body>
                            </Card>
    
                        </Container>
                </div>
        );
    };

    const onRowChange = (expanded, row) => {
        console.log("On row change: " + expanded + " - " + row.id);
        console.log(expandedUser);
        if( expanded === false ) {
            setExpandedUser(null);
        } else {
            setExpandedUser(row);
        }
    };

      /**
       * Return the user list page
       */
    return (
        <>
            <AppNavBar />
            <Container>
                <div class="d-flex flex-row">
                    <div class="col-sm-8">
                        <div class="jumbotron">
                            <h1>Liste d'utilisateurs ({sTotalSize})</h1>
                            <p class="lead">Le tableau contient la liste des utilisateurs qui peuvent s'autentifier via
                                l'application.</p>
                            {/* <Button variant="primary"><PersonAdd /> Ajouter un utilisateur</Button> */}
                            {/* <p></p>
                                <Form onSubmit={handleSearchSubmit}>
                                    <Form.Control type="text" className="me-auto" placeholder="recherche" value={aNewSearch} onChange={handleSearch} />
                                </Form> */}
                            <p>
                            ExpandedRow = { expandedUser ? JSON.stringify(expandedUser?.id) : null}
                            </p>
                            {userList ?
                                <DataTable
                                columns={nt_columns}
                                data={userList}
                                pagination
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={sTotalSize}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={handleOnSelectRow}
                                selectableRowsHighlight
                                striped
                                expandableRows
                                expandableRowExpanded = {isRowExpanded}
                                expandableRowsComponent={expandableComponent}
                                onRowExpandToggled={onRowChange}
                                />
                                :
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only"></span>
                                </div>}
                        </div>
                    </div >
                </div>
            </Container>

        </>

    );
};

export default UserList;