import { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const login = (email, password) =>
    supabase.auth.signInWithPassword({ email, password });

const signOut = () => supabase.auth.signOut();

const signUp = (email, password, firstName, lastName) =>  
    supabase.auth.signUp({
          email: email,
          password: password,
          options: {
            data: {
              first_name: firstName,
              last_name: lastName,
              profile: "urgences"
            },
            //emailRedirectTo: "https://hopefoneadmin.sixnapsis.com/signin"
          }
        });

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        const { data } = supabase.auth.onAuthStateChange((event, session) => {
            if (event === "SIGNED_IN") {
                setUser(session.user);
                setAuth(true);
                console.log("SIGNED_IN");
            } else if (event === "SIGNED_OUT") {
                setUser(null);
                setAuth(false);
                console.log("SIGNED_OUT");
            }
        });
        return () => {
            data.subscription.unsubscribe();
        };
    }, []);

   

return (
    <AuthContext.Provider value={{ auth, user, login, signUp, signOut }}>
        {children}
    </AuthContext.Provider>
);
};

export default AuthProvider;